import {
  typography,
  size,
  mediaQueries,
  InfoBlock as InfoBlockBase,
  Button,
} from '@everlywell/leaves';
import { MarkdownContent } from 'components/Contentful/MarkdownContent';
import { animated } from 'react-spring';
import styled from 'styled-components';

export const Section = styled.section`
  padding-top: ${size.xl4}px;
  padding-bottom: ${size.xl4}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: ${size.xl7}px;
    padding-bottom: ${size.xl7}px;
  }
`;

export const SectionTitle = styled.h3`
  ${typography.overlineText};
  margin-bottom: ${size.sm}px;
  text-transform: uppercase;
  font-weight: ${typography.weight.xbold};
  text-align: center;
`;

const SectionHeadline = styled.h2`
  ${typography.h4Text}
  margin-bottom: ${size.lg}px;
  text-align: center;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl3}px;
  }
`;

const InfoBlock = styled(InfoBlockBase)<{
  isImageOnTheSide?: boolean;
}>`
  margin-bottom: 56px;
  align-items: ${({ isImageOnTheSide }) => (isImageOnTheSide ? '' : 'center')};
  flex-direction: ${({ isImageOnTheSide }) =>
    isImageOnTheSide ? 'row' : 'column'};

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
    justify-content: space-between;
  }
`;

const AnimatedBlockContainer = styled(animated.div)<{
  isImageOnTheSide?: boolean;
}>`
  display: flex;
  justify-content: center;
  max-width: ${({ isImageOnTheSide }) => (isImageOnTheSide ? '' : '75%')};
  width: 100%;
`;

const InfoBlockContainer = styled.div<{
  isImageOnTheSide?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  ${({ isImageOnTheSide }) =>
    isImageOnTheSide
      ? mediaQueries.forTabletHorizontalUp
      : mediaQueries.forTabletVerticalUp} {
    margin: 0 -${size.md}px;

    ${AnimatedBlockContainer} {
      width: ${({ isImageOnTheSide }) => (isImageOnTheSide ? '33%' : '50%')};
      padding: 0 ${size.md}px;
    }
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    align-items: start;
    justify-content: ${({ isImageOnTheSide }) =>
      isImageOnTheSide ? 'start' : 'center'};
  }

  ${mediaQueries.forDesktopUp} {
    margin: 0 -${size.lg}px;

    /* Split items in a grid of 3 items per row */
    ${AnimatedBlockContainer} {
      width: 33.33%;
      padding: 0 ${size.lg}px;
    }

    /* Split items in a grid of 2 items per row ONLY when there are 4 items. Should snap back to 3/2 to accommodate 5 items and 3/3 for 6 (and so on). The interpolation tagging sc-selector was added below to fix styling lint error: https://styled-components.com/docs/tooling#interpolation-tagging */
    ${
      /* sc-selector */ AnimatedBlockContainer
    }:first-child:nth-last-child(3n + 1),
    ${
      /* sc-selector */ AnimatedBlockContainer
    }:first-child:nth-last-child(3n + 1) ~
    ${AnimatedBlockContainer} {
      width: 50%;
    }
  }

  ${AnimatedBlockContainer} {
    &:last-child {
      ${InfoBlock} {
        margin-bottom: 0;
      }
    }

    div[class*='InfoBlock-'] {
      div:first-child {
        background-color: transparent;
      }
    }
  }
`;

const MarkdownSection = styled(MarkdownContent)`
  padding-top: ${size.xs1}px;
  margin-bottom: ${size.xl4}px;
`;

export const Cta = styled(Button)`
  min-width: 150px;
  margin-top: ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    display: none;
  }
`;

export const DisclaimerText = styled.span`
  ${typography.captionText};
  margin-top: ${size.lg}px;
`;

export {
  SectionHeadline,
  InfoBlockContainer,
  AnimatedBlockContainer,
  InfoBlock,
  MarkdownSection,
};
