import React from 'react';

import HowItWorksSection from '../../../components/HowItWorksSection';
import { ContentWithImage } from '../../../utils/types';

export interface ContentAndVerticalInfoBlocksProps {
  content: {
    title: string;
    headline: string;
    description?: {
      childMarkdownRemark: {
        html: string;
      };
      description: string;
    };
    listOfContentWithImages: ContentWithImage[];
  };
}

export const ContentAndVerticalInfoBlocksContainer: React.FC<ContentAndVerticalInfoBlocksProps> =
  ({ content }) => {
    if (!content) return null;

    const { title, headline, listOfContentWithImages, description } = content;

    const formattedListOfContent = listOfContentWithImages.map((section) => ({
      title: section.heading || '',
      description: section.description?.description || '',
      image: section.image?.gatsbyImageData,
      imageAlt: section.image.description || '',
    }));

    return (
      <HowItWorksSection
        title={title}
        headline={headline}
        listOfContent={formattedListOfContent}
        description={description?.description}
        noNumberTags
      />
    );
  };

export default ContentAndVerticalInfoBlocksContainer;
