import loadable, { LoadableComponent } from '@loadable/component';
import { SEO } from 'components/SEO';
import React, { createContext, useEffect } from 'react';
import { useChain, useSpringRef, SpringRef } from 'react-spring';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { PageSectionType, ProductsBySlugType } from 'utils/types';
import {
  useProductBySlug,
  useProductVariants,
  useScienceData,
} from 'utils/hooks';

import Layout from '../components/Layout';
import {
  HeroContainer as HeroSection,
  HeroContainerProps,
} from '../containers/Enterprise/HeroContainer';
import { ContentAndFullWidthImageProps } from '../containers/PageSections/ContentAndFullWidthImage';
import { ContentAndParallaxContainerProps } from '../containers/PageSections/ContentAndParallax';
import {
  ContentAndVerticalInfoBlocksContainer as ContentAndVerticalInfoBlocks,
  ContentAndVerticalInfoBlocksProps,
} from '../containers/PageSections/ContentAndVerticalInfoBlocks';
import { DefaultProps } from '../containers/PageSections/Default';
import { SliderContainerProps } from '../containers/PageSections/Slider';
import { TwoColumnFullWidthImageProps } from '../containers/PageSections/TwoColumnFullWidthImage';
const ContentAndFullWidthImage = loadable(
  () => import('../containers/PageSections/ContentAndFullWidthImage'),
);
const DefaultSection = loadable(
  () => import('../containers/PageSections/Default'),
);
const SliderSection = loadable(
  () => import('../containers/PageSections/Slider'),
);
const TwoColumnFullWidthImage = loadable(
  () => import('../containers/PageSections/TwoColumnFullWidthImage'),
);

const ContentAndParallax = loadable(
  () => import('../containers/PageSections/ContentAndParallax'),
);

const META = {
  DESCRIPTION:
    "We take our science seriously, so we're highly selective about the labs we partner with - and we employ a team of experienced medical and clinical professionals.",
  KEYWORDS:
    'home health testing, online blood test, online health test, at home blood test, at home lab test, blood test, urine test, saliva test, food intolerance test, food sensitivity test, fertility test, hormone testing, self testing',
  URL: 'https://www.everlywell.com/science',
  TITLE: 'Everlywell - The Science Behind Our Tests',
};

const components: {
  HeroSection: React.FunctionComponent<HeroContainerProps>;
  ContentAndVerticalInfoBlocks: React.FunctionComponent<ContentAndVerticalInfoBlocksProps>;
  ContentAndFullWidthImage: LoadableComponent<ContentAndFullWidthImageProps>;
  TwoColumnFullWidthImage: LoadableComponent<TwoColumnFullWidthImageProps>;
  SliderSection: LoadableComponent<SliderContainerProps>;
  DefaultSection: LoadableComponent<DefaultProps>;
  ContentAndParallax: LoadableComponent<ContentAndParallaxContainerProps>;
} = {
  HeroSection,
  ContentAndVerticalInfoBlocks,
  ContentAndFullWidthImage,
  TwoColumnFullWidthImage,
  SliderSection,
  DefaultSection,
  ContentAndParallax,
};

export interface RefsContextType {
  [key: string]: SpringRef;
}

export const ProductInfoContext = createContext<ProductsBySlugType>({});
export const RefsForAnimationContext = createContext<RefsContextType>({});

export const sciencePageTemplate: React.FC = () => {
  const productVariants = useProductVariants();
  const productsBySlug = useProductBySlug(productVariants);
  const sciencePageContent = useScienceData();
  const { sections, heroSection, faqSchema } = sciencePageContent;

  if (!sections || !heroSection) {
    return null;
  }

  // Refs will be passed down in RefsForAnimationContext
  const animation1 = useSpringRef();
  const animation2 = useSpringRef();
  const animation3 = useSpringRef();
  const animation4 = useSpringRef();

  // by default useChain will space each animation 500ms apart
  // the 2nd argument overrides this default and defines the timing of the animations:
  // 1st animation happens immediately - 2nd animation starts 450ms after first animation ends
  // 3rd and 4th animations start 750ms after the first animation ends
  useChain([animation1, animation2, animation3, animation4], [0, 0.2, 1, 1]);

  useEffect(() => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        Page: 'Science Page',
      },
    });
  }, []);

  return (
    <>
      <SEO
        description={META.DESCRIPTION}
        keywords={META.KEYWORDS}
        url={META.URL}
        title={META.TITLE}
        faqSchema={faqSchema}
      />
      <Layout productsBySlug={productsBySlug}>
        <RefsForAnimationContext.Provider
          value={{ animation1, animation2, animation3, animation4 }}
        >
          <HeroSection content={heroSection} />
          {sections.map((section: PageSectionType, i: number) => {
            // @ts-ignore
            if (components[section.componentName]) {
              return React.createElement(
                // @ts-ignore
                components[section.componentName],
                {
                  content: section,
                  key: `${section.componentName}-${i}`, // handles the case for duplicate section keys
                },
              );
            }
          })}
        </RefsForAnimationContext.Provider>
      </Layout>
    </>
  );
};

export default sciencePageTemplate;
