import { Row, Col, size, Container } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { GatsbyImage as Img, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { animated as A, useSpring, config } from 'react-spring';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CTA } from 'utils/types';

import * as S from './styles';

type ContentWithImage = {
  title: string;
  description: string;
  image: IGatsbyImageData;
  imageAlt: string;
};

export interface HowItWorksSectionProps {
  title?: string;
  headline?: string;
  description?: string;
  listOfContent?: ContentWithImage[];
  noNumberTags?: boolean;
  isImageOnTheSide?: boolean;
  // To avoid any confusion - this disclaimerText prop is actually coming from the modalButtonText in Contentful
  disclaimerText?: string;
  // To avoid changing Contentful due to the re-architecture, this will use the Link content model from Contentful.
  cta?: CTA;
  onCtaClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const HowItWorksSection: React.FC<HowItWorksSectionProps> = ({
  title,
  headline,
  listOfContent,
  description,
  noNumberTags = false,
  isImageOnTheSide,
  disclaimerText,
  cta,
  onCtaClick,
}) => {
  const handleCtaClick: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    if (onCtaClick) {
      onCtaClick(event);
    }

    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        sectionHeader: headline,
        contentfulSection: 'HowItWorksSection',
        ctaText: cta?.text,
      },
    });
  };

  // TODO: add 300px offset supported by legacy hook if needed
  const [contentCardsEl, inView] = useInView({ triggerOnce: true });

  // triggered from useChain in enterprise template
  const titleTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl1}px)`,
    config: config.slow,
  });
  // triggered from useChain in enterprise template
  const displayTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl2}px)`,
    config: config.slow,
  });
  // triggered from scrolling into view
  const cardsTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl3}px)`,
    config: config.slow,
  });

  return (
    <SectionWrapper data-testid="howItWorksSection" ref={contentCardsEl}>
      <Container>
        {title ? (
          <Row center="xs">
            <Col xs={12} lg={10}>
              <A.div style={titleTransition}>
                <S.SectionTitle>{title}</S.SectionTitle>
              </A.div>
            </Col>
          </Row>
        ) : null}
        {headline ? (
          <Row center="xs">
            <Col xs={12} lg={10}>
              <A.div style={displayTransition}>
                <S.SectionHeadline>{headline}</S.SectionHeadline>
              </A.div>
            </Col>
          </Row>
        ) : null}
        {description ? (
          <Row center="xs">
            <Col xs={12}>
              <A.div style={displayTransition}>
                <S.MarkdownSection body={description} />
              </A.div>
            </Col>
          </Row>
        ) : null}
        {listOfContent ? (
          <Row center="xs" around="lg">
            <Col xs={12} lg={12}>
              <S.InfoBlockContainer isImageOnTheSide={isImageOnTheSide}>
                {listOfContent.length
                  ? listOfContent.map((content, i) => (
                      <S.AnimatedBlockContainer
                        key={i}
                        style={cardsTransition}
                        isImageOnTheSide={isImageOnTheSide}
                      >
                        <S.InfoBlock
                          title={content.title}
                          isImageOnTheSide={isImageOnTheSide}
                          image={
                            <Img
                              image={content.image}
                              style={{ width: '100%', height: '100%' }}
                              objectFit="contain"
                              alt={content.imageAlt}
                            />
                          }
                          imageAlt={content.imageAlt}
                          content={content.description}
                          size={isImageOnTheSide ? 'small' : 'xlarge'}
                          numberTag={noNumberTags ? undefined : i + 1}
                        />
                      </S.AnimatedBlockContainer>
                    ))
                  : null}
              </S.InfoBlockContainer>
            </Col>
            {cta ? (
              <S.Cta
                appearance="primary"
                // @ts-ignore TODO: Temporary ts-ignore to not block a deploy.
                onClick={handleCtaClick}
                href={cta.url}
              >
                {cta.text}
              </S.Cta>
            ) : null}
          </Row>
        ) : null}
        <Col xs={12} lg={12}>
          <Row center="xs" around="lg">
            <S.DisclaimerText>{disclaimerText}</S.DisclaimerText>
          </Row>
        </Col>
      </Container>
    </SectionWrapper>
  );
};

export default HowItWorksSection;
